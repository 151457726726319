body,
html,
#etalon-diagnostics {
  height: 100%;
  font-size: 16px;
  font-family: Roboto;
}

.h-100vh {
  height: 100vh;
}

.custom-control {
  z-index: auto;
}

.react-joyride__tooltip button[title='Next']:hover {
  background-color: #992933 !important;
}
.react-joyride__tooltip {
  padding: 30px 15px 15px 15px !important;
}
.react-joyride__tooltip p:last-child {
  margin-bottom: 0px !important;
}
